import { useEffect, useState } from "react";
import { Grid, Box, Typography, useMediaQuery } from "@mui/material";
import apiService from "services/apiService";
import { useSelector } from "react-redux";
import StripeCard from "./StripeCard";

const StripeProducts = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const smallScreen = useMediaQuery("(max-width: 477px)");

  useEffect(() => {
    const fetchStripeProducts = async () => {
      try {
        setLoading(true);
        const { data } = await apiService.fetchData("/stripe/products");
        setProducts(data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchStripeProducts();
  }, []);

  if (loading) return <div>Chargement des produits...</div>;
  if (error || !products)
    return <div>Erreur lors du chargement des produits</div>;

  return (
    <Grid
      container
      spacing={2}
      justifyContent={smallScreen ? "center" : "flex-start"}
    >
      {products.map((product) => {
        return <StripeCard result={product} />;
      })}
    </Grid>
  );
};

export default StripeProducts;
