import React from "react";
import { Button, Typography, Box, useTheme, Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Navbar from "scenes/navbar";

const CheckoutSuccess = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  const handleGoBack = () => {
    navigate("/"); // Redirige l'utilisateur vers la page d'accueil ou la boutique
  };

  return (
    <Box sx={{ height: "100%", overflowY: "scroll" }}>
      <Box
        sx={{
          position: "sticky",
          top: 0,
          backgroundColor: "#FFF",
          zIndex: 999,
        }}
      >
        <Navbar />
      </Box>
      <Divider />
      <Box
        sx={{
          display: "flex",
          backgroundColor: theme.palette.background.alt,
          height: "100%",
          width: "100%",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h1"
          component="h1"
          gutterBottom
          color="success.main"
        >
          Paiement Réussi !
        </Typography>
        <Typography variant="h5" textAlign="center" gutterBottom>
          Merci pour votre achat. Votre commande a été traitée avec succès. Vous
          recevrez un e-mail de confirmation sous peu.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleGoBack}
          sx={styles.button}
        >
          Retourner au formulaire
        </Button>
      </Box>
    </Box>
  );
};

const styles = {
  button: {
    marginTop: "2rem",
  },
};

export default CheckoutSuccess;
