import React from "react";
import { Button, Typography, Box, useTheme, Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Navbar from "scenes/navbar";

const CheckoutCancel = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  const handleGoBack = () => {
    navigate("/"); // Redirige l'utilisateur vers la page de la boutique
  };

  return (
    <Box sx={{ height: "100%", overflowY: "scroll" }}>
      <Box
        sx={{
          position: "sticky",
          top: 0,
          backgroundColor: "#FFF",
          zIndex: 999,
        }}
      >
        <Navbar />
      </Box>
      <Divider />
      <Box
        sx={{
          display: "flex",
          backgroundColor: theme.palette.background.alt,
          height: "100%",
          width: "100%",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h1" component="h1" gutterBottom color="error">
          Paiement Annulé
        </Typography>
        <Typography variant="h5" textAlign="center" gutterBottom>
          Votre processus de paiement a été annulé. Si vous avez des questions,
          n'hésitez pas à nous contacter.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleGoBack}
          sx={styles.button}
        >
          Retourner au formulaire
        </Button>
      </Box>
    </Box>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    backgroundColor: "#f8f8f8",
  },
  button: {
    marginTop: "2rem",
  },
};

export default CheckoutCancel;
